import React from "react";
import { useSelector } from "react-redux";

import { Action, Category, Label } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { useEventTracker } from "hooks/useEventTracker";
import { getEducationalMaterials } from "redux/selectors/complianceRules";
import addUtmParamsToDispensaryWebsite from "utils/dispensary/addUtmParamsToDispensaryWebsite";
import { isInMSOTestGroup } from "utils/dispensaryUtils";

import ExpandableContainer from "components/botanic/ExpandableContainer";
import DispensaryFeatures from "components/Dispensary/AboutDispensary/DispensaryFeatures";
import PhotoOverview from "components/Dispensary/AboutDispensary/PhotoOverview";
import PrimaryAddress from "components/Dispensary/AboutDispensary/PrimaryAddress";
import ReviewsOverview from "components/Dispensary/AboutDispensary/ReviewsOverview";
import SponsoredVideo from "components/Dispensary/AboutDispensary/SponsoredVideo";
import RetailHours from "components/Dispensary/RetailHours";
import AwardBadgeIcon from "components/Icons/award_badge.svg";
import HeartOutlineIcon from "components/Icons/heart_outline.svg";
import OpenInNewIcon from "components/Icons/open_in_new.svg";
import PhoneIcon from "components/Icons/phone.svg";
import EmailIcon from "components/Icons/share/email.svg";
import WebsiteIcon from "components/Icons/website.svg";
import TrackImpression from "components/TrackImpression";

import PromotionsSection from "../PromotionsSection";
import EducationalMaterialsStoreFrontImages from "./EducationalMaterials";
import { MSOLink } from "./MSOLink";
import UpdatesSection from "./Updates";

const AboutDispensary: React.FC<{ dispensary: Dispensary }> = ({
  dispensary,
}) => {
  const {
    country,
    created,
    description,
    email,
    featureTier,
    followerCount,
    id,
    leaflyListYears,
    licenseNumber,
    name,
    phone,
    retailType,
    schedules,
    slug,
    sponsoredVideoId,
    timeZone,
    topLevelOrganizationSlug,
    website,
  } = dispensary;

  const educationalMaterials = useSelector(getEducationalMaterials);

  const { publishEvent } = useEventTracker();

  const websiteWithUTM = addUtmParamsToDispensaryWebsite(website);

  const isInfoTier = featureTier === "info";

  const joinedYear = new Date(created).getFullYear();

  const trackAboutEvent = (label: string) => {
    publishEvent({
      action: Action.click,
      category: Category.dispensaryFooter,
      dispensaryId: id,
      label: label,
    });
  };

  return (
    <div className="container my-section" id="about-dispensary">
      <h3 className="heading--m mb-md">
        About this {country === "CA" ? "store" : retailType}
      </h3>
      <div className="flex flex-col gap-xxl">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-xl">
          <div className="flex flex-col gap-sm">
            <h4 className="text-xs uppercase">{name}</h4>
            {description && (
              <ExpandableContainer
                initialHeight="216px"
                hide={description.length < 300}
              >
                <p className="text-sm break-words whitespace-pre-wrap">
                  {description}
                </p>
              </ExpandableContainer>
            )}
            {topLevelOrganizationSlug &&
              isInMSOTestGroup(topLevelOrganizationSlug) && (
                <MSOLink slug={topLevelOrganizationSlug} />
              )}
          </div>
          <div className="flex flex-col gap-sm">
            <h3 className="text-xs uppercase">
              Leafly member since {joinedYear}
            </h3>
            <div className="flex flex-col gap-xl">
              <div className="flex flex-col gap-lg text-green">
                <div className="flex icon--centered">
                  <HeartOutlineIcon width="24" height="24" className="mr-sm" />
                  <div className="underline"> Followers: {followerCount} </div>
                </div>
                <PrimaryAddress dispensary={dispensary} />
                {email && !isInfoTier && (
                  <div className="flex icon--centered">
                    <div className="py-[5.25px] px-[2.5px] w-[24px] h-[24px] justify-center items-center box-border mr-sm">
                      <EmailIcon className="mr-sm" />
                    </div>
                    <a
                      data-testid={"about_dispensary__email"}
                      onClick={() => trackAboutEvent("email")}
                      href={`mailto:${email}`}
                      className="underline"
                    >
                      Send a message
                    </a>
                  </div>
                )}
                {phone && (
                  <div className="flex icon--centered">
                    <PhoneIcon width="24" height="24" className="mr-sm" />
                    <a
                      data-testid={"about_dispensary__call"}
                      onClick={() => trackAboutEvent("call")}
                      href={`tel:${phone}`}
                      className="underline"
                    >
                      Call {phone}
                    </a>
                  </div>
                )}
                {websiteWithUTM && !isInfoTier && (
                  <div className="flex icon--centered">
                    <WebsiteIcon width="24" height="24" className="mr-sm" />
                    <a
                      data-testid={"about_dispensary__visit_website"}
                      onClick={() => trackAboutEvent("visit website")}
                      href={websiteWithUTM}
                      rel="sponsored noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      Visit website
                    </a>
                    <OpenInNewIcon width="24" height="24" className="ml-xs" />
                  </div>
                )}
                {educationalMaterials?.licenseVerificationLink && (
                  <div className="flex icon--centered">
                    <WebsiteIcon width="24" height="24" className="mr-sm" />
                    <a
                      data-testid="edu-materials-link"
                      href={educationalMaterials.licenseVerificationLink.url}
                      rel="external"
                      target="_blank"
                      className="underline"
                    >
                      {educationalMaterials.licenseVerificationLink.text}
                    </a>
                    <OpenInNewIcon width="24" height="24" className="ml-xs" />
                  </div>
                )}
                {licenseNumber && (
                  <div className="text-sm text-default">
                    License {licenseNumber}
                  </div>
                )}
              </div>
              <DispensaryFeatures dispensary={dispensary} />
            </div>
          </div>

          <div className="flex flex-col gap-sm">
            {leaflyListYears.length > 0 && (
              <TrackImpression
                category={Category.dispensaryFooter}
                label={Label.leaflyListAwards}
                customDimensions={{ dispensaryId: id }}
              >
                <h3 className="text-xs uppercase">
                  <span>leafly list awards</span>
                </h3>
                <ul className="flex flex-col gap-sm">
                  {leaflyListYears.map((year: number) => (
                    <li className="flex items-center gap-xs" key={year}>
                      <span>
                        <AwardBadgeIcon width={28} height={28} />
                      </span>
                      <span className="font-bold text-xs">
                        {year}&nbsp;Leafly List Winner
                      </span>
                    </li>
                  ))}
                </ul>
              </TrackImpression>
            )}
            <RetailHours timeZone={timeZone} schedules={schedules} />
          </div>
        </div>
        {educationalMaterials?.storeFront?.images?.length && (
          <EducationalMaterialsStoreFrontImages
            images={educationalMaterials.storeFront.images}
          />
        )}
        {!isInfoTier && (
          <>
            <PhotoOverview photos={dispensary.photos} name={dispensary.name} />
            <PromotionsSection
              id={id}
              name={name}
              showDealsCta
              slug={slug}
              title={`Promotions at ${name}`}
              timeZone={timeZone}
            />
            <UpdatesSection dispensary={dispensary} />
            <SponsoredVideo sponsoredVideoId={sponsoredVideoId} />
          </>
        )}
        <ReviewsOverview dispensary={dispensary} />
      </div>
    </div>
  );
};

export default AboutDispensary;
